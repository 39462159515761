@use '~@strategies/tokens/color';
@use '~@strategies/tokens/font';


.Checkbox {
    width: font.$size--large;
    height: font.$size--large;

    border-radius: 50%;

    &.checked {
        background-color: color.$blue;

        &:after {
            content: url(../assets/checkmark.svg);

            position: absolute;
            left: 0;
            right: 0;
            top: 42%;
            transform: translateY(-50%);
            display: block;

            height: font.$size--large;
            margin: auto;

            pointer-events: none;
        }
    }
}

