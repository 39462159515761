@use '~@strategies/tokens/color';
@use '~@strategies/tokens/font';
@use '~@strategies/tokens/layout';


.TimeSlider {
    flex: 1 1;
    display: flex;
    align-items: center;

    height: 100%;
    padding: 0 layout.$padding--small;
    margin-left: layout.$padding--smallest;

    background-color: color.$blue--light;
    border-radius: layout.$border-radius--small;

    &.invisible {
        visibility: hidden;
    }

    .title {
      width: 5.91rem;
      text-transform: uppercase;

      font-size: font.$size--regular;

      .time-number {
        display: inline-block;
        margin: 0;
        text-align: right;
      }
    }

    .slider-comp {
        flex: 1 0 auto;
        margin: auto layout.$padding--small;
      .rc-slider {
        .rc-slider-rail {
          background-color: color.$white;
        }
        .rc-slider-track{
          background-color: color.$blue;
        }
        .rc-slider-handle {
            border: none;
          background-color: color.$black;
          margin-top: -3px;
          height: font.$size--regular;
          width: font.$size--regular;

          &:hover {
              box-shadow: layout.$box-shadow;
          }
        }
      }
    }

    .IconButton {
        background: none;
        &:hover {
            background: none;
        }
    }
}
