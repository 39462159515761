@use '~@strategies/tokens/color';
@use '~@strategies/tokens/font';
@use '~@strategies/tokens/layout';

.PanelSelect {
    height: 100%;

    select {
        height: 100%;

        font-size: font.$size--xlarge;

        background: color.$blue--light;
        border: none;
        border-radius: layout.$border-radius;
    }
}
