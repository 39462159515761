@use '~@strategies/tokens/color';
@use '~@strategies/tokens/font';
@use '~@strategies/tokens/layout';


.InfoModal {
    .Modal {
        &--window {
            max-width: 600px;
        }
    }

    header p {
        display: none;
    }

    ul {
        list-style: none;
        padding: 0;
    }

    li {
        display: flex;

        + li {
            margin-top: layout.$padding;
        }
    }

    label {
        flex: 0 0 120px;
        margin: auto;

        font-size: font.$size--large;
    }

    span {
        flex: 1;
        font-weight: font.$weight--regular;
        font-size: font.$size--large;
        text-align: left;
        border-radius: layout.$border-radius--small;
        background: color.$blue--lightest;
        padding: layout.$padding--small;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    footer {
        display: flex;
        align-items: right;
    }

    button.text {
        margin: auto;
        margin-left: 0 !important;
        font-size: font.$size--small !important;
    }

}
