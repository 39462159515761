@use '~@strategies/tokens/color';
@use '~@strategies/tokens/font';
@use '~@strategies/tokens/layout';

.no-colorizer {
    .SelectionInfo {
        width: auto;

        .title-bar {
            display: flex;
            padding: 3px 10px;
        }

        .row {
            margin: 0 layout.$padding--small;
            align-items: center;

            .name {
                width: auto;
                padding: 0;
                margin-right: layout.$padding--small;
            }

            .value {
                display: flex;
                flex: 0 0 max-content;
                align-content: center;
                padding: 5px 10px;
            }
        }

        .Button {
            flex: 1 1 max-content;
            width: max-content;
            margin-top: 0;
            height: 100%;
            padding: 10px 15px;
            display: flex;
            align-items: center;
        }

        .panel {
            display: flex;
            height: 40px;

            .content {
                display: flex;
            }

            .info {
                display: flex;
            }
        }
    }
}

.SelectionInfo {
  position: fixed;
  top: 0;
  right: 0;

  width: 214px;

  pointer-events: none;

  .panel {
    margin-top: layout.$padding--small;
    margin-right: layout.$padding--small;
    padding: 0;

    font-weight: font.$weight--regular;

    background: color.$white;
    border-radius: layout.$border-radius--small;
    overflow: hidden;
    pointer-events: all;
    box-shadow: layout.$box-shadow;
  }

  .title-bar {
    align-items: center;

    padding: layout.$padding--small layout.$padding;

    color: color.$blue--lightest;
    font-size: font.$size--large;

    background-color: color.$blue;
    overflow: visible;
  }

  .content {
    padding: layout.$padding--small;
  }

  .row {
    display: flex;

    margin-bottom: layout.$padding--small;

    .name {
      width: 8.571rem;
      padding: layout.$padding--small;

      text-align: right;
      text-transform: uppercase;
    }

    .value {
      width: 100%;
      padding: layout.$padding--small;

      background-color: color.$blue--lightest;
      border-radius: layout.$border-radius;
    }
  }

  .buttons {
    display: flex;

    button {
      flex: 1;
      width: 100%;
      margin-top: layout.$padding;

      border: 1px solid color.$blue;
    }
  }


}
