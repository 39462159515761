@use '~@strategies/tokens/layout';


.ScenarioModal {
    z-index: 999999;

    .Modal {
        &--window {
            min-width: 600px;
        }
    }

    header p {
        display: none;
    }

    .ReactForm__Input + .ReactForm__Input {
        margin-top: layout.$padding;
    }

}
