.SummaryChart {
    ul {
        padding: 0 $dims_padding;
        list-style: none;
        display: flex;
        flex-direction: column;
    }

    li {
        flex: 1 0 25%;

        width: 100%;
        height: auto;
        margin: auto;
        padding: $dims_padding * 0.5;

        background: $color_bg-light;
        border-radius: $box_border-radius--small;
        border:1px solid $color_lightest-blue;

        + li {
            margin-top: $dims_padding;
        }

        &:nth-of-type(1) p {
            color: #6666FF;
        }
        &:nth-of-type(2) p {
            color: #66EDFF;
        }
        &:nth-of-type(3) p {
            color: #FF8A66;
        }
        &:nth-of-type(4) p {
            color: #6666FF;
        }
    }

    h5 {
        font-size: $font_size-regular;
        border-bottom: none;
        font-weight: 500;
        margin: 0;
        margin-bottom: $dims_padding;
    }

    p {
        margin: 0;
        padding: 0;
        text-align: center;
        font-size: $font_size-medium;
        font-weight: 600;
    }
}
