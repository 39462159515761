@use '~@strategies/tokens/layout';
@import "./core";

.Dashi {
    .ReactTimeline {
        $_radius: $box_border-radius--small;
        color: $color_font-dark;
        background: none;

        &__Block {
            border: 2px solid $color_bg-dark;
            border-radius: $box_border-radius--small;

            &-label {
                color: $color_font-dark;
                font-size: $font_size-small;
            }

            &--left {
                border-radius: $_radius 0 0 $_radius;
                border-right: none;

                &--selected {
                }
            }

            &--right {
                border-radius: 0 $_radius $_radius 0;
                border-left: none;
            }

            &--selected {
                border-color: $color_light-blue;
                box-shadow: 0 0 5px 0px rgba(5, 0, 5, .5);
            }
        }

        &__Scrubber {
            width: 2px;
            background: #716f8d;
        }
    }

    .ReactModal {
        opacity: 0;
        pointer-events: none;
        transition: opacity .2s;

        &.active {
            opacity: 1;
            pointer-events: all;
            transition: opacity .2s;

            .ReactModal__window {
                top: 50%;
                transition: top .3s;
            }
        }

        header {
            padding: $dims_padding-large;

            color: $color_light-blue;
            font-size: $font_size-large;
            font-weight: 400;

            background: $color_lightest-blue;
        }

        .content {
            padding: ($dims_padding-large * 2) $dims_padding-large $dims_padding-large;
        }

        &__gutter {
            background: rgba(5, 0, 56, .4);
        }

        &__window {
            top: 55%;

            padding: 0;

            background: $color_bg-light;
            border-radius: $box_border-radius--medium;
            box-shadow: $box_shadow;
            overflow: hidden;
            transition: top .3s;
        }

        &__content {
            padding: 0;
        }

        &__close {
            display: none;
        }

        .modal-buttons {
            display: flex;
            align-items: right;
            text-align: right;
            margin-top: $dims_padding-large * 2;

            button {
                margin-left: auto;

                font-size: $font_size-medium;
                font-weight: 500;

                &.primary, &.warn {
                    margin-left: $dims_padding-large;
                }
            }
        }
    }
}

.panels {
  @include no-bg;

  position: absolute;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;
  padding: layout.$padding--small;
}

//hack to prevent iframe issue interfering with user interaction
iframe {
  display: none;
}

.user-guide-window iframe {
    display: block;
}

@import './Login';
@import './MenuBar';
@import './FileModal';
@import './Filters';
@import './InfoModal';
@import '~rc-slider/assets/index.css';
@import './TimeSlider';
@import './TimelinePanel';
@import './ProjectSettings';
@import './ScenarioModal';
@import './SelectionInfo';
@import './Checkbox/Checkbox';
@import './ProjectList/ProjectList';
@import './ToolBar';
@import './charts/ChartHeader/ChartHeader';
@import './ChartsPanel';
@import './charts/charts';
@import './PanelSelect';
@import './WelcomeModal';
@import './Colorizer/components/Colorizer';

