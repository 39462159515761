@use '~@strategies/tokens/color';
@use '~@strategies/tokens/font';
@use '~@strategies/tokens/layout';

@import '~@szhsin/react-menu/dist/index.css';


.ToolBar {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: calc(#{layout.$padding--small * 2} + 3.5714rem);
    left: layout.$padding--small;
    z-index: 3;

    border-radius: layout.$border-radius--small;
    box-shadow: layout.$box-shadow;

    ul {
        border-radius: layout.$border-radius--small;
        box-shadow: layout.$box-shadow;
        overflow: hidden;
        transform: translateX(#{layout.$padding});
    }

    .IconButton {
        border-radius: 0;
    }

    .szh-menu-button {
        padding: 0;
    }

    > button {
        overflow: hidden;

        &:first-of-type {
            border-top-left-radius: layout.$border-radius--small;
            border-top-right-radius: layout.$border-radius--small;
        }

        &:last-of-type {
            border-bottom-left-radius: layout.$border-radius--small;
            border-bottom-right-radius: layout.$border-radius--small;
        }
    }

    li {
        padding: 0.375rem 1rem;

        color: color.$black;
        font-size: font.$size--small;
        font-weight: font.$weight--medium;

        img, span {
            display: inline-block;

            width: font.$size--large;
            height: font.$size--large;
            margin-right: layout.$padding--small;
        }

        span {
            border: 1px solid color.$black;
            border-radius: 3px;
        }
    }

    .no-hover {
        li {
            cursor: initial;
            background: initial !important;
        }
    }
}

