@use '~@strategies/tokens/layout';


.SummaryChart {
    min-height: auto !important;

    ul {
        flex-direction: row;
        gap: layout.$padding--small;
    }

    li {
        flex: 1 1 25%;
        margin: 0;

        + li {
            margin-top: 0;
        }

        &:nth-of-type(1) p {
            color: #c00f8f;
        }

        &:nth-of-type(2) p {
            color: #790fc0;
        }

        &:nth-of-type(3) p {
            color: #059861;
        }

        &:nth-of-type(4) p {
            color: #af7402;
        }
    }
}
