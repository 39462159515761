@use '~@strategies/tokens/color';
@use '~@strategies/tokens/layout';


.ProjectSettings.Modal {
    $this: &;
    $_width: 60.143rem;

    header p {
        display: none;
    }

    .Modal {
        &--window {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 0;
            left: -$_width;
            right: unset;

            width: $_width;
            min-width: unset;
            height: 100vh;

            transition: left .6s;
        }
    }

    &.active {
        .Modal--window {
            left: 0;
        }
    }

    main {
        flex: 1 auto;
        overflow-y: auto;
        padding: 0 layout.$padding;
        max-height: unset;
    }

    section {
        padding: layout.$padding;

        + section {
            border-top: 1px solid color.$black;
        }

        input {
            outline: none;
        }

        .ReactForm__Input {
            width: 125px;
        }

        .cost-sibling + .ReactForm__Input {
            flex: 0 0 150px;
        }

        &.top {
            .project-name {
                width: 100%;
                max-width: 35rem;
            }
        }

        &.timestamps {
            .ReactForm__Group-children {
                align-items: top;
            }

            .month, select {
                width: 150px;
            }

            .year {
                width: 90px;
            }

            .quarter {
                width: 45px;

                text-align: center;

                label {
                    position: absolute;
                    left: 0;
                    right: 9;
                    margin-left: auto;
                    margin-right: auto;
                }

                .ReactForm__Input-input {
                    width: 45px !important;
                    margin: auto;
                }
            }

            .duration {
                margin-left: 3em;
                width: 165px;
            }
        }

        textarea {
            height: 10em;
            resize: none;
        }

        .cost-sibling {
            flex: 1 1 auto;
        }
    }

    footer {
        .delete-project {
            float: left;
        }
    }
}

.delete-project-confirmation {
    .Modal--window {
        min-width: 40rem;
    }

    header p {
        display: none;
    }
}

